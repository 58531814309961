<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="mdiSendOutline"
      class="py-2"
    ></BaseBreadcrumb>

    <v-data-table
      :loading="isTableLoading"
      loading-text="Cargando..."
      :server-items-length="itemsTotalCount"
      :options.sync="tableOptions"
      :headers="tableHeaders"
      item-key="id"
      :items="items"
      sort-by="itemDateTime"
      class="elevation-4"
      :sort-desc="true"
      disable-sort
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50]
      }"
    >
      <template v-slot:top class="pb-2">
        <v-row no-gutters class="pa-4">
          <input
            v-show="false"
            type="file"
            ref="fileInput"
            @change="goToSignPDF"
            accept="application/pdf"
            multiple
          />

          <v-btn
            :disabled="isTableLoading"
            color="primary"
            @click="onAcceptConfigurationSign"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiPlus }}
            </v-icon>
            Nuevo Envío
          </v-btn>

          <v-btn
            class="ma-2 btn--expanded"
            :disabled="isTableLoading"
            outlined
            color="primary"
            @click="dialogFiltering = true"
          >
            <v-icon left>
              {{ mdiFilterOutline }}
            </v-icon>
            FILTROS
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            :disabled="isTableLoading"
            outlined
            color="primary"
            @click="clearAndRefresh()"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiRefresh }}
            </v-icon>
            Refrescar
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="ma-2 btn--expanded"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="exportCSV" :disabled="isTableLoading">
                <v-list-item-title>Exportar CSV</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>

        <v-row no-gutters class="mb-n4" v-if="userTeams.length > 1">
          <v-col cols="12" md="6" xl="4">
            <v-select
              dense
              class="mx-4 mt-2"
              outlined
              :items="userTeams"
              v-model="onUserTeamActive"
              @change="onUserTeamActiveUpdated"
              label="Equipo activo"
            ></v-select>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span>{{ item.id }}</span>
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="ml-2"
              medium
              @click="onClickCopyId(item.id)"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>Copiar</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.itemDateTime`]="{ item }">
        {{ new Date(item.itemDateTime).toLocaleString() }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-icon small class="mr-1" :color="getStatusColor(item.status)">
          {{ mdiCircle }}
        </v-icon>
        {{ getStatusText(item.status) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                v-on="on"
                @click="showItemDetail(item)"
              >
                <v-icon>
                  mdi-eye-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Mostrar detalle</span>
          </v-tooltip>

          <v-menu transition="scale-transition" absolute min-width="250px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                v-on="on"
                @click="openMoreOptionsMenu(item)"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>

            <v-list v-if="moreOptionsMenu" ref="listTest">
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="documentDetailsLoading"
                ></v-progress-circular>
              </div>
              <div>
                <v-list-item
                  v-if="isCancelable(item.status) && !documentDetailsLoading"
                  @click="cancelItem(item)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>
                          mdi-cancel
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Cancelar documento</span>
                  </v-tooltip>
                  <span class="ml-1">Cancelar documento</span>
                </v-list-item>
                <v-list-item
                  v-if="
                    isDownloadable(item.status) &&
                      isDocumentPurgable &&
                      !documentDetailsLoading
                  "
                  @click="downloadAllDocuments()"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>
                          mdi-download-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar documentos</span>
                  </v-tooltip>
                  <span class="ml-1">Descargar documentos</span>
                </v-list-item>

                <v-list-item
                  v-if="isErrorShowable(item.status) && !documentDetailsLoading"
                  @click="showItemError(item)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>
                          mdi-alert-circle-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Mostrar error</span>
                  </v-tooltip>
                  <span class="ml-1">Mostrar error</span>
                </v-list-item>
                <v-list-item
                  v-if="
                    isCancelable(item.status) &&
                      sendMethod === 'eMail' &&
                      !documentDetailsLoading
                  "
                  @click="resendItem(item)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>
                          mdi-email-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Reenviar documento</span>
                  </v-tooltip>
                  <span class="ml-1">Reenviar documento</span>
                </v-list-item>

                <v-list-item
                  v-if="!documentDetailsLoading"
                  @click="downloadEvidence(item)"
                >
                  <v-tooltip :z-index="100" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>
                          mdi-playlist-star
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar evidencias</span>
                  </v-tooltip>
                  <span class="ml-1 ">Descargar evidencias</span>
                </v-list-item>

                <v-list-item
                  v-if="
                    isPurgable(item.status) &&
                      isDocumentPurgable &&
                      !documentDetailsLoading
                  "
                  @click="removeDocuments(item)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon color="#e54448">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Borrar documentos</span>
                  </v-tooltip>
                  <span class="ml-1">Borrar documentos</span>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-row>
      </template>

      <template v-if="getHistoricPSCItemsError" v-slot:no-data>
        <v-btn outlined class="ma-4" color="primary" @click="getItems()">
          Reintentar
        </v-btn>
      </template>

      <template v-if="CSVExportError" v-slot:no-data>
        <v-btn outlined class="ma-4" color="primary" @click="exportCSV">
          Reintentar descarga
        </v-btn>
      </template>

      <template v-slot:footer.prepend>
        <v-row>
          <v-spacer> </v-spacer>
          <v-btn
            icon
            class="mr-4"
            @click="loadFirstsItems"
            :disabled="isTableLoading || tableOptions.page === 1"
          >
            <v-icon>
              {{ mdiChevronDoubleLeft }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            class="mr-8"
            @click="loadLastsItems"
            :disabled="
              isTableLoading ||
                tableOptions.page * tableOptions.itemsPerPage >= itemsTotalCount
            "
          >
            <v-icon>
              {{ mdiChevronDoubleRight }}
            </v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog scrollable v-model="isDownloading" persistent max-width="344">
      <Loading :visible="isDownloading" :message="downloadingText()" />
    </v-dialog>
    <v-dialog
      scrollable
      v-model="isRemovingEvidence"
      persistent
      max-width="344"
    >
      <Loading
        :visible="isRemovingEvidence"
        :message="removingEvidenceText()"
        bgColor="error"
      />
    </v-dialog>
    <v-dialog scrollable v-model="dialogMethodSign" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          Nuevo envío
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-radio-group v-model="optionCreate">
              <v-radio
                :key="'default'"
                :label="'Creación predeterminada'"
                :value="0"
              ></v-radio>
              <v-radio
                class="mt-4"
                :key="'flow'"
                :label="'Creación mediante flujo'"
                :value="1"
              ></v-radio>
            </v-radio-group>
            <v-select
              v-if="optionCreate === 1"
              label="Seleccionar flujo"
              :items="itemsFlows"
              v-model="flowSelected"
              required
              outlined
              dense
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialogMethodSign = false">
            Cerrar</v-btn
          >

          <v-btn
            color="primary"
            text
            :disabled="optionCreate === 1 && flowSelected === null"
            @click="onAcceptConfigurationSign"
          >
            Solicitar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogCancel" max-width="350">
      <v-card>
        <v-card-title class="headline justify-center mb-4">
          <v-icon size="70" color="primary">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text
          class="font-weight-bold headline justify-center text-center"
        >
          Confirmar cancelación
        </v-card-text>
        <v-card-text
          class="text-center pb-3 color-grey-text"
          v-html="cancelTextConfirmation()"
        ></v-card-text>
        <v-card-actions class="justify-center pa-4">
          <v-btn color="primary" text @click="closeCancel()">Volver</v-btn>
          <v-btn
            :loading="isCancelling"
            color="primary"
            class="ml-5"
            @click="cancelItemConfirm()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogError" max-width="400">
      <Loading
        :visible="documentSetError === null"
        :message="'Recuperando error...'"
      />
      <v-card v-if="documentSetError != null">
        <v-card-title class="text-h5">
          Ficha error
        </v-card-title>

        <v-card-text>
          <h4>
            Nombre del sobre
          </h4>
          <p>
            {{ selectedItem && selectedItem.documentSetName }}
          </p>

          <h4>
            Información de error
          </h4>
          <p>
            {{ documentSetError.errorInfo }}
          </p>

          <h4>
            Fecha del error
          </h4>
          <p>
            {{ new Date(documentSetError.eventDateTime).toLocaleString() }}
          </p>

          <h4>
            Tipo de error
          </h4>
          <p>
            {{ getErrorTypeById(documentSetError.errorType) }}
          </p>

          <h4 v-if="documentSetError.reTrys">
            Número de reintentos
          </h4>
          <p v-if="documentSetError.reTrys">
            {{ documentSetError.reTrys }} reintento(s)
          </p>

          <h4>
            Estado de la operación
          </h4>
          <p>
            {{ documentSetError.finished ? "Finalizada" : "Sin finalizar" }}
          </p>

          <h4 v-if="documentSetError.nextTry != null">
            Próximo reintento
          </h4>
          <p v-if="documentSetError.nextTry != null">
            {{ new Date(documentSetError.nextTry).toLocaleString() }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogError = false" text color="primary">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogAuditTrail" max-width="800">
      <Loading
        :visible="documentSetAuditTrail === null"
        :message="'Recuperando eventos...'"
      />
      <v-card v-if="documentSetAuditTrail != null">
        <v-card-title v-if="selectedItem">
          Ficha eventos
        </v-card-title>

        <v-card-text>
          <h4>Nombre del sobre</h4>
          <p>
            {{ selectedItem && selectedItem.documentSetName }}
          </p>

          <h4>Número total de eventos</h4>
          <p>
            {{ documentSetAuditTrail.length }}
          </p>

          <h4>Cronología</h4>
          <v-timeline align-top dense class="mt-2">
            <div :key="i" v-for="(event, i) in documentSetAuditTrail">
              <v-timeline-item
                small
                v-show="!(event.eventType === 303 && hidePageEvents === true)"
              >
                <v-row>
                  <v-col cols="12" sm="4" class="pt-0">
                    <strong class="color-grey-text">
                      {{ new Date(event.eventDateTime).toLocaleString() }}
                    </strong>
                  </v-col>
                  <v-col class="pt-0">
                    <h4 class="primary--text">
                      {{ getEventAuditTrail(event.eventType) }}
                    </h4>
                    <div class="caption ml-1 mt-1" v-if="event.recipientId">
                      <v-icon small>mdi-account</v-icon>
                      <span class="color-grey-text">
                        Participante:
                        {{ getRecipientNameById(event.recipientId) }}
                      </span>
                    </div>
                    <div class="caption ml-1 mt-1" v-if="event.documentId">
                      <v-icon small>mdi-file-document</v-icon>
                      <span class="color-grey-text">
                        Documento:
                        {{ getDocumentNameById(event.documentId) }}
                      </span>
                    </div>
                    <div
                      class="caption ml-1 mt-1"
                      v-if="event.data.MotivoRechazo"
                    >
                      <v-icon small>mdi-cancel</v-icon>
                      <span class="color-grey-text">
                        Motivo de rechazo : {{ event.data.MotivoRechazo }}
                      </span>
                    </div>
                    <div class="caption ml-1 mt-1" v-if="event.data.pageNumber">
                      <v-icon small>mdi-book-open-page-variant</v-icon>
                      <span class="color-grey-text">
                        Número de página : {{ event.data.pageNumber }}
                      </span>
                    </div>
                    <div class="caption ml-1 mt-1" v-if="event.data.IpAddress">
                      <v-icon small>mdi-router-wireless</v-icon>
                      <span class="color-grey-text">
                        Dirección IP: {{ event.data.IpAddress }}</span
                      >
                    </div>
                    <div class="caption ml-1 mt-1" v-if="event.data.UserAgent">
                      <v-icon small>mdi-web</v-icon>
                      <span class="color-grey-text">
                        Navegador Web : {{ event.data.UserAgent }}</span
                      >
                    </div>
                    <div class="caption ml-1 mt-1" v-if="event.data.Gps">
                      <v-icon small>mdi-map-marker</v-icon>
                      <span class="color-grey-text">
                        Localización : {{ event.data.Gps }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </div>
          </v-timeline>
          <span class="caption mt-2">
            * Todas las fechas se muestran tomando como referencia la hora local
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="detailEventsRefresh()"
            :disabled="updatingSeeEventsDetail"
            text
            color="primary"
          >
            Refrescar
          </v-btn>

          <v-spacer> </v-spacer>
          <v-btn @click="dialogAuditTrail = false" text color="primary">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogFiltering" persistent width="600">
      <v-card>
        <v-card-title class="text-h5">
          Filtros
        </v-card-title>

        <v-card-text>
          <h4 class="mt-2">Filtros básicos</h4>

          <v-select
            class="mt-2"
            v-model="itemFilteringStatus"
            :items="documentStatus"
            label="Estados"
            :prepend-inner-icon="mdiStateMachine"
            outlined
            multiple
            clearable
            small-chips
            dense
          ></v-select>

          <v-menu
            ref="filteringDatesMenu"
            v-model="filteringDatesMenu"
            :close-on-content-click="false"
            :return-value.sync="itemFilteringDates"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="itemFilteringDatesText"
                label="Rango de fechas"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                outlined
                clearable
                dense
                @click:clear="clearFilteringDates"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :allowed-dates="allowedItemFilteringDates"
              v-model="itemFilteringDates"
              range
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="filteringDatesMenu = false">
                Cancelar
              </v-btn>
              <v-btn
                v-if="itemFilteringDates"
                text
                color="primary"
                :disabled="itemFilteringDates.length < 2"
                @click="updateDates"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-text-field
            v-model="itemSearchById"
            prepend-inner-icon="mdi-key"
            label="Id del sobre"
            outlined
            clearable
            dense
          ></v-text-field>

          <h4 class="mt-2">Filtros avanzados</h4>

          <v-alert
            border="left"
            class="mt-2"
            colored-border
            type="info"
            elevation="2"
            dense
          >
            <span class="text-body-2"
              >Con los filtros avanzados, debes especificar un rango de fechas
              que no supere los <strong>90 días</strong></span
            >
          </v-alert>

          <v-text-field
            v-model="DocumentSetName"
            label="Nombre del sobre"
            outlined
            clearable
            dense
          ></v-text-field>

          <v-text-field
            v-model="itemFilteringReference"
            label="Referencia del sobre"
            outlined
            clearable
            dense
          ></v-text-field>

          <v-text-field
            v-model="itemSearchByName"
            label="Nombre del documento"
            outlined
            clearable
            dense
          ></v-text-field>

          <v-text-field
            v-model="RecipientName"
            label="Nombre del destinatario"
            outlined
            clearable
            dense
          ></v-text-field>

          <v-text-field
            v-model="RecipientEmail"
            label="Email del destinatario"
            outlined
            clearable
            dense
          ></v-text-field>

          <v-text-field
            v-model="RecipientPhoneNumber"
            label="Teléfono del destinatario"
            outlined
            clearable
            dense
          ></v-text-field>

          <v-text-field
            v-model="RecipientCardId"
            label="DNI del destinatario"
            outlined
            clearable
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="clearAndRefresh">Limpiar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialogFiltering = false">
            Cerrar
          </v-btn>
          <v-btn text color="primary" @click="filterItems"> Filtrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogDetail" width="500">
      <Loading
        :visible="documentSetDetails === null"
        :message="'Recuperando detalle...'"
      />
      <v-card v-if="documentSetDetails != null">
        <v-card-title class="text-h5">
          Ficha detalle
        </v-card-title>

        <v-card-text>
          <h3 class="mb-n5">
            Participantes ({{ documentSetDetails.recipients.length }})
          </h3>

          <div
            class="mt-6"
            :key="recipient.id"
            v-for="recipient in documentSetDetails.recipients"
          >
            <v-row no-gutters>
              <v-icon x-small>mdi-circle-medium</v-icon>
              Nombre: {{ recipient.name }}
            </v-row>

            <v-row no-gutters class="mt-1">
              <v-icon x-small>mdi-circle-medium</v-icon>
              Email: {{ recipient.email }}
            </v-row>

            <v-row no-gutters v-if="recipient.cardId" class="mt-1">
              <v-icon x-small>mdi-circle-medium</v-icon>
              Card Id: {{ recipient.cardId }}
            </v-row>

            <v-row no-gutters v-if="recipient.phoneNumber" class="mt-1">
              <v-icon x-small>mdi-circle-medium</v-icon>
              Teléfono: {{ recipient.phoneNumber }}
            </v-row>

            <v-row no-gutters class="mt-1">
              <v-icon x-small>mdi-circle-medium</v-icon>
              Estado:
              {{
                getRecipientActionStatus(
                  recipient.recipientActions[0].actionStatus
                )
              }}

              <v-icon
                class="pb-1 ml-1"
                small
                :color="
                  getColorRecipientActionStatus(
                    recipient.recipientActions[0].actionStatus
                  )
                "
              >
                mdi-circle
              </v-icon>
            </v-row>

            <v-row no-gutters class="mt-1">
              <v-icon x-small>mdi-circle-medium</v-icon>
              Acción a realizar:
              {{
                getRoleByActionTypeId(recipient.recipientActions[0].actionType)
              }}
            </v-row>

            <v-row no-gutters class="mt-1">
              <v-icon x-small>mdi-circle-medium</v-icon>
              Método de autenticación: {{ getAuthTypeName(recipient.authType) }}
            </v-row>

            <div v-if="recipient.attachments.length > 0" class="mt-2">
              <v-btn
                small
                outlined
                color="primary"
                block
                text
                @click="
                  dialogAttachments = true;
                  selectedParticipant = recipient;
                "
                >Ver anexos</v-btn
              >
            </div>
          </div>

          <h3 class="mt-5 mb-2">Información del sobre</h3>

          <h4>Id del sobre</h4>
          <p>
            {{ documentSetDetails.documentSetId }}
          </p>

          <h4 v-if="documentSetDetails.documentSetReference">Referencia</h4>
          <p v-if="documentSetDetails.documentSetReference">
            {{ documentSetDetails.documentSetReference }}
          </p>

          <h4>Nombre del sobre</h4>
          <p>
            {{ documentSetDetails.documentSetName }}
          </p>

          <h4 v-if="documentSetDetails.flowName">Flujo</h4>
          <p v-if="documentSetDetails.flowName">
            {{ documentSetDetails.flowName }}
          </p>

          <h4>Equipo</h4>
          <p>
            {{ documentSetDetails.teamName }}
          </p>

          <h4>Documentos ({{ documentSetDetails.documents.length }})</h4>
          <p
            v-for="document in documentSetDetails.documents"
            :key="document.id"
          >
            <v-btn
              v-if="
                isDownloadable(documentSetDetails.status) && isDocumentPurgable
              "
              small
              icon
              @click="downloadDocumentFromDocumentSet(document)"
              ><v-icon color="primary">mdi-download</v-icon></v-btn
            >
            {{ document.name }}
          </p>

          <h4>Estado del sobre</h4>
          <p>
            <v-icon
              small
              class="mr-1 pb-1"
              :color="getStatusColor(documentSetDetails.status)"
            >
              {{ mdiCircle }}
            </v-icon>
            <span>{{ getStatusText(documentSetDetails.status) }}</span>
          </p>

          <h4>Último cambio de estado del sobre</h4>
          <p>
            {{ new Date(documentSetDetails.statusTime).toLocaleString() }}
          </p>

          <h4>Método de envío</h4>
          <p>
            {{ sendMethod }}
          </p>

          <h4>Fecha de creación del sobre</h4>
          <p>
            {{ new Date(documentSetDetails.creationDate).toLocaleString() }}
          </p>

          <h4>Fecha de expiración del sobre</h4>
          <p>
            {{ new Date(documentSetDetails.expirationDate).toLocaleString() }}
          </p>

          <h4>Fecha estimada de eliminación de los documentos</h4>
          <p>
            {{
              new Date(
                documentSetDetails.estimatedPurgationDate
              ).toLocaleString()
            }}
          </p>
          <div v-if="!isDocumentPurgable">
            <h4>Fecha de eliminación a demanda de los documentos</h4>
            <p>
              {{ new Date(documentSetDetails.purgationDate).toLocaleString() }}
            </p>
          </div>

          <h4
            v-if="
              documentSetDetails.listDatesReminderDays &&
                documentSetDetails.listDatesReminderDays.length > 0
            "
          >
            Próximo recordatorio del sobre
          </h4>

          <p
            v-if="
              documentSetDetails.listDatesReminderDays &&
                documentSetDetails.listDatesReminderDays.length > 0
            "
          >
            {{
              new Date(
                documentSetDetails.listDatesReminderDays[0]
              ).toLocaleString()
            }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="showItemAuditTrail(documentSetDetails)"
            color="primary"
            text
            >Ver eventos</v-btn
          >

          <v-btn
            text
            color="primary"
            @click="detailRefresh()"
            :disabled="updatingSeeDetail"
          >
            Refrescar
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn @click="dialogDetail = false" text color="primary">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogResend" max-width="350">
      <v-card>
        <v-card-title class="headline justify-center mb-4">
          <v-icon size="70" color="primary">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text
          class="font-weight-bold headline justify-center text-center"
        >
          Confirmar reenvío
        </v-card-text>
        <v-card-text
          class="text-center pb-3 color-grey-text"
          v-html="resendTextConfirmation()"
        ></v-card-text>
        <v-card-actions class="justify-center pa-4">
          <v-btn color="primary" text @click="closeResend()">Volver</v-btn>
          <v-btn
            :loading="isResending"
            class="ml-5"
            color="primary"
            @click="resendItemConfirm()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogAttachments" width="300">
      <v-card>
        <v-card-title class="text-h5">
          Anexos
        </v-card-title>

        <v-card-text>
          <ul
            :key="attachment"
            v-for="attachment in selectedParticipant.attachments"
          >
            <li>
              <p>
                {{ attachment.description }} (Obligatorio:
                {{ attachment.required ? "Sí)" : "No)" }}
              </p>
            </li>

            <p v-if="attachment.files.length == 0">No se ha adjuntado</p>
            <p :key="file" v-for="file in attachment.files">
              {{ file.name }}
              <v-btn
                icon
                @click="downloadAttachment(file)"
                v-if="selectedItem.status === 300"
              >
                <v-icon v-bind="attrs" v-on="on">
                  mdi-download-outline
                </v-icon>
              </v-btn>
            </p>

            <p></p>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogAttachments = false" text color="primary">
            Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogRemove" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Borrar documentos del sobre
        </v-card-title>

        <v-card-text>
          Se borrarán los documentos pero se mantiene la información del envío.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogRemove = false" text color="primary">
            Cancelar
          </v-btn>
          <v-btn @click="onremoveDocuments()" text color="#e54448">
            Borrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      scrollable
      v-model="isAttachmentDownloading"
      persistent
      max-width="344"
    >
      <Loading
        :visible="isAttachmentDownloading"
        message="Descargando anexo..."
      />
    </v-dialog>
  </v-container>
</template>

<script>
import {
  mdiStateMachine,
  mdiSendOutline,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiPlus,
  mdiRefresh,
  mdiCircle,
  mdiFilterOutline
} from "@mdi/js";
import Loading from "@/components/commons/Loading";
import { SetAddDay } from "../../../utils/utils";
import Constants from "./ConstantsPSC";

export default {
  name: "HistoricSignatures",

  components: {
    Loading
  },

  props: {
    file: {
      type: File,
      required: false
    },
    reference: {
      type: String,
      required: false
    },
    docId: {
      type: String,
      required: false
    }
  },

  created() {
    if (this.file != null) {
      this.openSetSignView();
    }
  },
  mounted() {
    this.onUserTeamActive = this.userTeamActive;
  },
  data: () => ({
    page: {
      title: "Documentos Enviados"
    },
    tableOptions: {},
    tableHeaders: [
      {
        text: "NOMBRE",

        value: "documentSetName"
      },
      {
        text: "ID",
        value: "id"
      },
      { text: "ESTADO", value: "status" },
      { text: "FECHA", value: "itemDateTime" },
      {
        text: "ACCIONES",
        value: "actions"
      }
    ],
    loadingFlows: false,
    referenceLabel: "Buscar por referencia",
    pageRowsLabel: "Filas por página",
    idDocumentLabel: "Buscar por Id",
    DocumentNameLabel: "Buscar por nombre de documento",
    RecipientCardIdLabel: "Buscar por DNI del participante",
    RecipientEmailLabel: "Buscar por email del participante",
    RecipientPhoneNumberLabel: "Buscar por teléfono del participante",
    DocumentSetNameLabel: "Buscar por nombre de sobre",
    RecipientNameLabel: "Buscar por nombre del participante",
    hoverTooltipSearch: false,
    onUserTeamActive: null,
    files: [],
    optionCreate: 0,
    itemsFlows: [],
    flowSelected: null,
    dialogMethodSign: false,
    mdiStateMachine: mdiStateMachine,
    mdiSendOutline: mdiSendOutline,
    mdiChevronDoubleLeft: mdiChevronDoubleLeft,
    mdiChevronDoubleRight: mdiChevronDoubleRight,
    mdiPlus: mdiPlus,
    mdiRefresh: mdiRefresh,
    mdiCircle: mdiCircle,
    mdiFilterOutline: mdiFilterOutline,
    itemFilteringDates: [],
    filteringDatesMenu: false,
    CSVExportError: false,

    documentStatus: [
      { value: 100, text: "Creado" },
      { value: 200, text: "En proceso" },
      { value: 300, text: "Completado" },
      { value: 400, text: "Cancelado" },
      { value: 500, text: "Rechazado" },
      { value: 600, text: "Eliminado" },
      { value: 700, text: "Caducado" },
      { value: 800, text: "Erróneo" }
    ],
    itemFilteringStatus: [],
    itemFilteringReference: null,
    itemSearchById: null,
    itemSearchByName: null,
    DocumentName: null,
    RecipientEmail: null,
    RecipientPhoneNumber: null,
    RecipientCardId: null,
    DocumentSetName: null,
    RecipientName: null,

    selectedParticipant: {},
    selectedItem: null,
    updatingSeeDetail: false,
    updatingSeeEventsDetail: false,
    documentSetDetails: undefined,
    documentSetError: null,
    getHistoricPSCItemsError: false,
    documentSetAuditTrail: undefined,

    isTableLoading: false,
    isCancelling: false,
    isResending: false,
    isDownloading: false,
    isAttachmentDownloading: false,

    authTypeValues: [
      { value: 0, text: "Sin autenticación previa" },
      { value: 10, text: "Aceptación de datos personales" },
      { value: 20, text: "Clave de acceso" },
      { value: 30, text: "Autenticación MRZ" },
      { value: 40, text: "Envío de SMS" },
      { value: 41, text: "Envío de WhatsApp" }
    ],
    dialogFiltering: false,
    dialogResend: false,
    dialogCancel: false,
    dialogDetail: false,
    dialogError: false,
    dialogAuditTrail: false,
    dialogAttachments: false,
    hidePageEvents: false,
    dialogRemove: false,
    isRemovingEvidence: false,
    moreOptionsMenu: false,
    isDocumentPurgable: true,
    documentDetailsLoading: false
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogCancel(val) {
      val || this.closeCancel();
    },
    dialogResend(val) {
      val || this.closeResend();
    },
    tableOptions: {
      deep: true,
      handler() {
        this.getItems();
      }
    },
    userTeamActive(newVal) {
      this.onUserTeamActive = newVal;
    }
  },
  computed: {
    userTeamActive() {
      return this.$store.getters.activeTeamTokenId;
    },
    itemFilteringDatesText() {
      var itemFilteringDates = [];
      if (this.itemFilteringDates && this.itemFilteringDates.length > 0) {
        for (var i = 0; i < this.itemFilteringDates.length; i++) {
          const [year, month, day] = this.itemFilteringDates[i].split("-");
          itemFilteringDates.push(`${day}/${month}/${year}`);
        }
      }
      return itemFilteringDates.join(" ~ ");
    },

    items() {
      return (
        this.$store.getters.historicPSCItems &&
        this.$store.getters.historicPSCItems.map(item => {
          if (!item.documentSetName)
            item.documentSetName = "Envio de documento sin nombre";
          return item;
        })
      );
    },

    itemsTotalCount() {
      return this.$store.getters.historicCountPSCItems;
    },

    userTeams() {
      var userProfile = this.$store.getters["user/userProfile"];
      if (userProfile && userProfile.teams) {
        return userProfile.teams.map(team => {
          return { value: team.teamTokenId, text: team.name };
        });
      }
      return [];
    },
    sendMethod() {
      switch (this.documentSetDetails?.sendMethod) {
        case 0:
          return "URL";
        case 1:
          return "eMail";
        case 2:
          return "SMS";
        default:
          return "WhatsApp";
      }
    }
  },

  methods: {
    clearAndRefresh() {
      this.itemSearchById = String();
      this.itemSearchByName = String();
      this.DocumentName = String();
      this.RecipientEmail = String();
      this.RecipientPhoneNumber = String();
      this.RecipientCardId = String();
      this.DocumentSetName = String();
      this.RecipientName = String();
      this.itemFilteringReference = String();
      this.clearFilteringStatus();
      this.clearFilteringDates();
      this.dialogFiltering = false;
      this.refreshItems();
    },

    refreshItems() {
      if (this.tableOptions.page === 1) {
        this.getItems();
      } else {
        this.tableOptions.page = 1;
      }
    },

    onUserTeamActiveUpdated(teamTokenId) {
      this.$store.commit("SET_ACTIVE_TEAM_TOKEN_ID", teamTokenId);
      this.clearAndRefresh();
    },

    getAuthTypeName(authType = 0) {
      return this.authTypeValues.find(authValue => {
        return authValue.value === authType;
      })?.text;
    },

    openSetSignView() {
      if (this.userTeams.length === 0) {
        this.$toasted.global.toast_error({
          message: "No estás asociado a ningún equipo"
        });
        return;
      }

      this.isTableLoading = true;
      this.loadingFlows = true;

      this.$store
        .dispatch("getFlows", {
          limit: 50,
          offset: 0,
          active: true
        })
        .then(data => {
          if (data) {
            let flowsTypeEnvelope = data.filter(flow => {
              return flow.flowType == 0;
            });
            this.itemsFlows = flowsTypeEnvelope.map(flow => {
              return { value: flow.flowTokenId, text: flow.name };
            });
          }

          if (this.itemsFlows && this.itemsFlows.length) {
            this.dialogMethodSign = true;
          } else {
            this.dialogMethodSign = false;
            this.loadingFlows = false;
            this.onAcceptConfigurationSign();
          }
        })
        .catch(err => {
          console.log(err);
          this.dialogMethodSign = false;
          this.loadingFlows = false;
          this.onAcceptConfigurationSign();
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    filterItems() {
      this.refreshItems();
      this.dialogFiltering = false;
    },

    onClickCopyId(id) {
      navigator.clipboard.writeText(id ? id : this.computedSelectItem.id);
      this.$toasted.global.toast_success({
        message: "Se ha copiado el identificador del flujo al portapapeles"
      });
    },

    loadFirstsItems() {
      if (this.tableOptions.page === 1) {
        this.getItems();
      } else {
        this.tableOptions.page = 1;
      }
    },

    loadLastsItems() {
      let page = Math.ceil(
        this.itemsTotalCount / this.tableOptions.itemsPerPage
      );
      if (this.tableOptions.page !== page) {
        this.tableOptions.page = page;
      } else {
        this.getItems();
      }
    },

    openNewDocumentSet(files) {
      this.$router.push({
        name: "NewDocumentSet",
        params: {
          files: files,
          flowTokenId: this.optionCreate === 0 ? null : this.flowSelected,
          reference: this.reference,
          docId: this.docId
        }
      });
    },

    onAcceptConfigurationSign() {
      if (this.file != null) {
        //Aquí entraría si recibe un documento de un complemento
        this.openNewDocumentSet([this.file]);
        return;
      }
      if (this.userTeams.length === 0) {
        this.$toasted.global.toast_error({
          message: "No estás asociado a ningún equipo"
        });
        return;
      }
      this.$refs.fileInput.click();
    },

    goToSignPDF(event) {
      var files = [...event.target.files];
      var filesSize = files.map(file => file.size).reduce((a, b) => a + b, 0);
      var filesSizeInB64 = filesSize * 1.33;
      if (filesSizeInB64 > Constants.PSC_CREATE_DOCUMENTSET_MAX_SIZE_IN_BYTES) {
        this.$toasted.global.toast_error({
          message: "El tamaño de el/los archivo(s) supera el límite permitido"
        });
      } else {
        this.openNewDocumentSet(files);
      }
    },

    getInputFile() {
      this.$refs.fileInput.click();
    },

    downloadingText() {
      if (this.selectedItem != null) {
        return "Descargando documento " + this.selectedItem.documentSetName;
      }
      return "";
    },
    removingEvidenceText() {
      if (this.selectedItem != null) {
        return "Borrando documentos";
      }
      return "";
    },

    getStatusText(status) {
      var statusFound = this.documentStatus.find(element => {
        return element.value == status;
      });

      return statusFound != undefined ? statusFound.text : "";
    },

    getRoleByActionTypeId(actionTypeId) {
      switch (actionTypeId) {
        case 10:
          return "Notificación";
        case 20:
          return "Aceptación";
        case 21:
          return "Aceptación con firma";
        case 30:
          return "Firma manuscrita";
        case 40:
          return "Firma OTP";
        case 41:
          return "Firma OTP WhatsApp";
        case 50:
          return "Firma manuscrita avanzada + OTP";
        case 51:
          return "Firma manuscrita avanzada + OTP WhatsApp";
        case 60:
          return "Certificado de firma";
        default:
          return "";
      }
    },

    getColorRecipientActionStatus(actionStatusId) {
      switch (actionStatusId) {
        case 1:
          return "#20a9ef";
        case 2:
          return "#20a9ef";
        case 3:
          return "#20a9ef";
        case 4:
          return "#52bc03";
        case 5:
          return "#f5ad00";
        case 6:
          return "#52bc03";
        case 7:
          return "#52bc03";
        case 8:
          return "#e54448";
        default:
          return "#20a9ef";
      }
    },

    getRecipientActionStatus(actionStatusId) {
      switch (actionStatusId) {
        case 1:
          return "Creado";
        case 2:
          return "Pendiente";
        case 3:
          return "Firmando";
        case 4:
          return "Aceptado";
        case 5:
          return "Rechazado";
        case 6:
          return "Notificado";
        case 7:
          return "Firmado";
        case 8:
          return "Cancelado";
        default:
          return "";
      }
    },

    getStatusColor(status) {
      switch (status) {
        case 100:
          return "#20a9ef";
        case 200:
          return "#20a9ef";
        case 300:
          return "#52bc03";
        case 400:
          return "#e54448";
        case 500:
          return "#f5ad00";
        case 600:
          return "#e54448";
        case 700:
          return "#e54448";
        case 800:
          return "#e54448";
        default:
          return "#20a9ef";
      }
    },

    getErrorTypeById(typeErrorId) {
      switch (typeErrorId) {
        case 0:
          return "No aplica";
        case 1:
          return "Error interno del sistema";
        case 2:
          return "Error en el envío de correo";
        case 3:
          return "Error en el envío de SMS";
        case 4:
          return "Error en el envío de WhatsApp";
        case 5:
          return "Error en la validación de los OTP";
        case 6:
          return "Error en la validación de los OTP por WhatsApp";
        case 7:
          return "Error modulo interno encriptación";
        case 8:
          return "Error en validación de código de acceso";
        case 9:
          return "Error en validación de MRZ";
        case 10:
          return "Archivo PDF no válido";
        default:
          return "Desconocido";
      }
    },

    getEventAuditTrail(eventId) {
      switch (eventId) {
        case 100:
          return "Sobre creado";
        case 101:
          return "Sobre en proceso";
        case 102:
          return "Sobre completado";
        case 103:
          return "Sobre expirado";
        case 104:
          return "Sobre cancelado";
        case 105:
          return "Sobre eliminado";
        case 106:
          return "Sobre rechazado";
        case 107:
          return "Envío de recordatorio";
        case 108:
          return "Sobre erróneo (reintentos superados)";
        case 109:
          return "Sobre recuperado antes de superar reintentos";
        case 110:
          return "Sobre purgado manualmente";
        case 201:
          return "Participante en proceso";
        case 202:
          return "Participante completado";
        case 300:
          return "Documento creado";
        case 301:
          return "Documento en proceso";
        case 302:
          return "Documento Abierto";
        case 303:
          return "Página leída";
        case 304:
          return "Documento leído";
        case 305:
          return "Documento aceptado";
        case 306:
          return "Documento notificado";
        case 307:
          return "Documento firmado";
        case 308:
          return "Documento rechazado";
        case 309:
          return "Documento resellado";
        case 310:
          return "Otp validado";
        case 311:
          return "Otp no validado";
        case 312:
          return "Firma bio recibida";
        case 313:
          return "Sin autenticación previa";
        case 314:
          return "Términos y condiciones aceptadas";
        case 315:
          return "Autenticado con Otp";
        case 316:
          return "Autenticado con Otp fallida";
        case 317:
          return "Autenticación de acceso";
        case 318:
          return "Autenticación de acceso fallida";
        case 322:
          return "Autenticación con PKCS8 fallida";
        case 323:
          return "Autenticado con Otp WhatsApp";
        case 324:
          return "Autenticado con Otp WhatsApp fallida";
        case 360:
          return "Sobre Cancelado";
        case 399:
          return "Peticion mail proveedor";
        case 400:
          return "Correo entregado";
        case 401:
          return "Correo procesado";
        case 402:
          return "Correo abierto";
        case 403:
          return "Click dentro del correo";
        case 404:
          return "Correo descartado";
        case 405:
          return "Correo diferido";
        case 406:
          return "Correo rebotado";
        case 407:
          return "Correo bloqueado";
        case 408:
          return "Correo reportado como spam";
        case 409:
          return "Correo de desuscripción";
        case 410:
          return "Correo de desuscripción a grupo";
        case 411:
          return "Resuscrito a grupo";
        case 499:
          return "Peticion sms proveedor";
        case 500:
          return "SMS entregado";
        case 501:
          return "SMS desconocido";
        case 502:
          return "SMS pendiente";
        case 503:
          return "SMS expirado";
        case 504:
          return "SMS no entregado";
        case 505:
          return "SMS rechazado";
        case 599:
          return "WhatsApp enviado";
        case 600:
          return "WhatsApp entregado";
        case 601:
          return "WhatsApp leído";
        case 602:
          return "WhatApp no enviado por error";
        case 603:
          return "WhatApp no entregado";
        case 604:
          return "WhatApp error desconocido";
        case 699:
          return "Sobre enviado.";
        case 700:
          return "Error al enviar el sobre.";
        default:
          return "Acción realizada";
      }
    },

    isCancelable(status) {
      return status === 100 || status === 200;
    },

    isDownloadable(status) {
      return status === 300;
    },

    isErrorShowable(status) {
      return status === 800;
    },
    isPurgable(status) {
      return (
        status === 300 ||
        status === 400 ||
        status === 500 ||
        status === 600 ||
        status === 700 ||
        status === 800
      );
    },
    closeCancel() {
      this.dialogCancel = false;
    },

    /** DOWNLOAD EVIDENCE */
    downloadEvidence(item) {
      this.selectedItem = item;
      this.isDownloading = true;

      this.$store
        .dispatch("downloadPSCEvidenceItem", this.selectedItem.id)
        .then(response => {
          if (response.status == 200) {
            this.processSuccessDownload(response.data);
          } else {
            this.processErrorDownload();
          }
        })
        .catch(error => {
          console.log(error);
          this.processErrorDownload();
        });
    },

    /** END DOWNLOAD EVIDENCE DOCUMENT */

    /* DOWNLOAD DOCUMENT FROM DOCUMENTSET */
    downloadDocumentFromDocumentSet(document) {
      this.$store
        .dispatch("downloadDocumentFromDocumentSet", {
          documentSetId: this.selectedItem.id,
          documentId: document.id
        })
        .then(response => {
          if (response.status == 200) {
            this.processSuccessDownload(response.data);
          } else {
            this.processErrorDownload();
          }
        })
        .catch(error => {
          console.log(error);
          this.processErrorDownload();
        });
    },

    /** DOWNLOAD DOCUMENT */

    downloadDocument(item) {
      this.selectedItem = item;
      this.isDownloading = true;

      this.$store
        .dispatch("downloadPSCItem", this.selectedItem.id)
        .then(response => {
          if (response.status == 200) {
            this.processSuccessDownload(response.data);
          } else {
            this.processErrorDownload();
          }
        })
        .catch(error => {
          console.log(error);
          this.processErrorDownload();
        });
    },
    onremoveDocuments() {
      this.isRemovingEvidence = true;
      this.dialogRemove = false;
      this.$store
        .dispatch("removeDocuments", this.selectedItem.id)
        .then(() => {
          this.isRemovingEvidence = false;
          this.$toasted.global.toast_success({
            message: "Documentos eliminados correctamente"
          });
        })
        .catch(error => {
          this.isRemovingEvidence = false;
          this.$toasted.global.toast_error({
            message: "No se han podido eliminar los documentos"
          });
          console.log(error);
        });
    },
    processSuccessDownload(data) {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      if (this.selectedItem)
        link.setAttribute(
          "download",
          this.selectedItem.documentSetName + ".pdf"
        );
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.isDownloading = false;
    },

    processErrorDownload() {
      if (this.selectedItem) {
        this.$toasted.global.toast_error({
          message:
            "El documento: " +
            this.selectedItem.documentSetName +
            ", no se ha podido descargar"
        });
        this.isDownloading = false;
        this.selectedItem = null;
      }
    },

    /** END DOWNLOAD DOCUMENT */

    /** DOWNLOAD ATTACHMENT */

    downloadAttachment(file) {
      this.isAttachmentDownloading = true;

      this.$store
        .dispatch("downloadAttachmentItem", {
          documentSetId: this.selectedItem.id,
          attachmentId: file.tokenId
        })
        .then(response => {
          if (response.status == 200) {
            this.processSuccessAttachmentDownload(response.data, file.name);
          } else {
            this.processErrorAttachmentDownload(file.name);
          }
        })
        .catch(error => {
          console.log(error);
          this.processErrorAttachmentDownload(file.name);
        });
    },

    processSuccessAttachmentDownload(data, name) {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      if (this.selectedItem) link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.isAttachmentDownloading = false;
    },

    processErrorAttachmentDownload(name) {
      if (this.selectedItem) {
        this.$toasted.global.toast_error({
          message: "El anexo: " + name + ", no se ha podido descargar"
        });
        this.isAttachmentDownloading = false;
      }
    },

    /** END DOWNLOAD ATTACHMENT */

    /** HISTORIC */
    clearFilteringStatus() {
      this.itemFilteringStatus = [];
      this.getItems();
    },

    allowedItemFilteringDates(val) {
      if (this.itemFilteringDates && this.itemFilteringDates.length === 1) {
        return val >= this.itemFilteringDates[0];
      }
      return val;
    },

    clearFilteringDates() {
      this.itemFilteringDates = [];
    },

    updateDates() {
      this.$refs.filteringDatesMenu.save(this.itemFilteringDates);
    },

    startDates() {
      let filterDate = new Date();
      filterDate.setDate(filterDate.getDate() - 1);
      const date = SetAddDay(filterDate);
      filterDate = new Date();
      filterDate.setMonth(filterDate.getMonth() - 1);
      filterDate.setDate(filterDate.getDate() - 1);
      const date30 = SetAddDay(filterDate);
      this.itemFilteringDates[0] = date30;
      this.itemFilteringDates[1] = date;
      this.filterItems();
    },

    getItems() {
      let itemFilteringDates = [];

      if (this.itemFilteringDates && this.itemFilteringDates.length === 2) {
        itemFilteringDates = JSON.parse(
          JSON.stringify(this.itemFilteringDates)
        );
        itemFilteringDates[1] = SetAddDay(this.itemFilteringDates[1]);
      }

      let itemSearchById = this.itemSearchById;
      let itemSearchByName = this.itemSearchByName;
      let itemFilteringReference = this.itemFilteringReference;
      let DocumentName = this.DocumentName;
      let RecipientEmail = this.RecipientEmail;
      let RecipientPhoneNumber = this.RecipientPhoneNumber;
      let RecipientCardId = this.RecipientCardId;
      let RecipientName = this.RecipientName;
      let itemFilteringStatus = this.itemFilteringStatus;
      let DocumentSetName = this.DocumentSetName;

      let offset =
        this.tableOptions.itemsPerPage * (this.tableOptions.page - 1);
      let limit = this.tableOptions.itemsPerPage;
      let teamTokenId = this.$store.getters.activeTeamTokenId;
      this.isTableLoading = true;
      this.$store
        .dispatch("getHistoricPSCItems", {
          itemFilteringReference,
          DocumentName,
          RecipientEmail,
          RecipientPhoneNumber,
          RecipientCardId,
          RecipientName,
          itemSearchById,
          itemSearchByName,
          itemFilteringStatus,
          itemFilteringDates,
          DocumentSetName,
          limit,
          offset,
          teamTokenId
        })
        .then(() => {
          this.getHistoricPSCItemsError = false;
          this.isTableLoading = false;
        })
        .catch(err => {
          this.getHistoricPSCItemsError = true;
          this.isTableLoading = false;
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se han podido obtener los sobres"
          });
        });
    },
    /** END HISTORIC */

    exportCSV() {
      let itemFilteringDates = [];

      if (this.itemFilteringDates && this.itemFilteringDates.length === 2) {
        itemFilteringDates = JSON.parse(
          JSON.stringify(this.itemFilteringDates)
        );
        itemFilteringDates[1] = SetAddDay(this.itemFilteringDates[1]);
      }

      let itemSearchById = this.itemSearchById;
      let itemFilteringStatus = this.itemFilteringStatus;
      let itemFilteringReference = this.itemFilteringReference;
      let itemSearchByName = this.itemSearchByName;
      let DocumentName = this.DocumentName;
      let RecipientEmail = this.RecipientEmail;
      let RecipientPhoneNumber = this.RecipientPhoneNumber;
      let RecipientCardId = this.RecipientCardId;
      let DocumentSetName = this.DocumentSetName;
      let RecipientName = this.RecipientName;
      let teamTokenId = this.$store.getters.activeTeamTokenId;

      let offset =
        this.tableOptions.itemsPerPage * (this.tableOptions.page - 1);
      let limit = this.tableOptions.itemsPerPage;

      this.isTableLoading = true;
      this.$store
        .dispatch("getCSVExport", {
          itemSearchById,
          itemFilteringStatus,
          itemFilteringDates,
          itemFilteringReference,
          itemSearchByName,
          DocumentName,
          RecipientEmail,
          RecipientPhoneNumber,
          RecipientCardId,
          DocumentSetName,
          RecipientName,
          limit,
          offset,
          teamTokenId
        })
        .then(() => {
          this.CSVExportError = false;
        })
        .catch(err => {
          console.log(err);
          this.CSVExportError = true;
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    /** RESEND DOCUMENT */
    resendItem(item) {
      this.selectedItem = item;
      this.dialogResend = true;
    },

    resendItemConfirm() {
      this.isResending = true;
      this.resendDocument();
    },

    resendTextConfirmation() {
      if (this.selectedItem != null) {
        return (
          "¿Desea confirmar el reenvío del documento <strong>" +
          this.selectedItem.documentSetName +
          "</strong>?"
        );
      }
      return "";
    },

    resendDocument() {
      this.isResending = true;

      this.$store
        .dispatch("resendPSCItem", this.selectedItem.id)
        .then(response => {
          if (response.status === 200) {
            this.processSuccessResend();
          } else {
            this.processErrorResend();
          }
        })
        .catch(err => {
          console.log(err);
          this.processErrorResend();
        });
    },

    processSuccessResend() {
      this.isTableLoading = false;
      this.dialogResend = false;
      this.isResending = false;
      if (this.selectedItem) {
        this.$toasted.global.toast_success({
          message:
            "El documento: " +
            this.selectedItem.documentSetName +
            ", se ha reenviado correctamente"
        });
      }

      this.selectedItem = null;
    },

    processErrorResend() {
      this.isTableLoading = false;
      this.dialogResend = false;
      this.isResending = false;
      if (this.selectedItem) {
        this.$toasted.global.toast_error({
          message:
            "El documento: " +
            this.selectedItem.documentSetName +
            ", no se ha podido reenviar correctamente"
        });
      }
      this.selectedItem = null;
    },

    closeResend() {
      this.dialogResend = false;
    },
    /** END RESEND DOCUMENT */

    /** AUDIT TRAIL */
    showItemAuditTrail() {
      this.dialogDetail = false;
      this.getDocumentAuditTrail();
      this.dialogAuditTrail = true;
    },

    detailRefresh() {
      this.updatingSeeDetail = true;
      this.getDocumentDetails(void 0, true, true, () => {
        setTimeout(() => {
          this.updatingSeeDetail = false;
        }, 500);
      });
    },

    detailEventsRefresh() {
      this.updatingSeeEventsDetail = true;
      this.getDocumentAuditTrail(true, () => {
        setTimeout(() => {
          this.updatingSeeEventsDetail = false;
        }, 500);
      });
    },

    getDocumentAuditTrail(isUpdated = false, onSuccess) {
      if (!isUpdated) this.documentSetAuditTrail = null;

      this.$store
        .dispatch("getAuditTrailPSCItem", this.documentSetDetails.documentSetId)
        .then(response => {
          if (response.status === 200) {
            if (isUpdated) {
              this.$toasted.global.toast_success({
                message: "Actualizado correctamente."
              });
            }
            this.processSuccessAuditTrail(response);
            if (onSuccess) onSuccess();
          } else {
            this.processErrorAuditTrail();
          }
        })
        .catch(err => {
          console.log(err);
          this.processErrorAuditTrail();
        });
    },

    processSuccessAuditTrail(response) {
      this.documentSetAuditTrail = response.data;
      this.documentSetAuditTrail.sort(function(a, b) {
        if (a.eventDateTime < b.eventDateTime) {
          return 1;
        }
        if (a.eventDateTime > b.eventDateTime) {
          return -1;
        }
        return 0;
      });
    },

    processErrorAuditTrail() {
      if (this.selectedItem) {
        this.$toasted.global.toast_error({
          message:
            "No se han podido recuperar los eventos del sobre: " +
            this.selectedItem.documentSetName
        });
      }
      this.dialogAuditTrail = false;
      this.resetSelectedItem();
    },

    getDocumentNameById(documentId) {
      if (this.documentSetDetails != null) {
        const document = this.documentSetDetails.documents.find(
          document => document.id === documentId
        );
        if (document != null) {
          return document.name;
        }
      }
      return "";
    },

    getRecipientNameById(recipientId) {
      if (this.documentSetDetails != null) {
        const recipient = this.documentSetDetails.recipients.find(
          recipient => recipient.recipientid === recipientId
        );
        if (recipient != null) {
          return recipient.name;
        }
      }
      return "";
    },
    /** END AUDIT TRAIL */

    /** DOCUMENT DETAILS */
    showItemDetail(item) {
      this.selectedItem = item;
      this.getDocumentDetails(void 0, true);
      this.dialogDetail = true;
    },
    removeDocuments(item) {
      this.selectedItem = item;
      this.dialogRemove = true;
    },
    getDocumentDetails(
      searchById = String(),
      showingError,
      isUpdate = false,
      onSuccess
    ) {
      if (!isUpdate) this.documentSetDetails = null;
      this.$store
        .dispatch(
          "getDetailPSCItem",
          searchById ? searchById : this.selectedItem.id
        )
        .then(response => {
          this.documentDetailsLoading = false;
          this.isDocumentPurgable = !response.data.purgated;
          if (response.status === 200) {
            if (isUpdate) {
              this.$toasted.global.toast_success({
                message: "Actualizado correctamente."
              });
            }
            this.processSuccessDetails(response);
            if (onSuccess) onSuccess();
            if (searchById) {
              this.$toasted.global.toast_success({
                message: "Se ha recuperado el sobre correctamente"
              });
              this.selectedItem = {
                documentSetName: response.data.documentSetName,
                id: response.data.documentSetId,
                itemDateTime: response.data.creationDate,
                status: response.data.status
              };
            }
          } else {
            this.processErrorDetails(showingError);
          }
        })
        .catch(err => {
          this.$toasted.global.toast_error({
            message: "No se han podido recuperar los detalles del sobre"
          });
          console.log(err);
          this.processErrorDetails(showingError);
        });
    },

    processSuccessDetails(response) {
      this.documentSetDetails = response.data;
    },

    processErrorDetails(showingError) {
      if (showingError && this.selectedItem) {
        this.$toasted.global.toast_error({
          message:
            "No se han podido recuperar los detalles del sobre:" +
            this.selectedItem.documentSetName
        });
      }
      this.dialogDetail = false;
    },
    /** END DOCUMENT DETAILS */

    /** CANCEL DOCUMENT **/
    cancelItem(item) {
      this.selectedItem = item;
      this.dialogCancel = true;
    },

    cancelItemConfirm() {
      this.isCancelling = true;
      this.cancelDocument();
    },

    cancelTextConfirmation() {
      if (this.selectedItem != null) {
        return (
          "¿Desea confirmar la cancelación del documento <strong>" +
          this.selectedItem.documentSetName +
          "</strong>?"
        );
      }
      return "";
    },

    cancelDocument() {
      this.isCancelling = true;
      this.$store
        .dispatch("cancelPSCItem", this.selectedItem.id)
        .then(response => {
          if (response.status === 200) {
            this.processSuccessCancel();
          } else {
            this.processErrorCancel();
          }
        })
        .catch(err => {
          console.log(err);
          this.processErrorCancel();
        });
    },

    processSuccessCancel() {
      var editedIndex = this.items.indexOf(this.selectedItem);
      this.selectedItem.status = 400;
      Object.assign(this.items[editedIndex], this.selectedItem);
      this.dialogCancel = false;
      this.isCancelling = false;

      if (this.selectedItem) {
        this.$toasted.global.toast_success({
          message:
            "El documento: " +
            this.selectedItem.documentSetName +
            ", se ha cancelado correctamente"
        });
      }
      this.resetSelectedItem();
    },

    processErrorCancel() {
      this.dialogCancel = false;
      this.isCancelling = false;

      if (this.selectedItem) {
        this.$toasted.global.toast_error({
          message:
            "El documento: " +
            this.selectedItem.documentSetName +
            ", no se ha podido cancelar"
        });
      }
      this.resetSelectedItem();
    },
    //** END CANCEL DOCUMENT */

    /** DOCUMENT ERROR */
    showItemError(item) {
      this.selectedItem = item;
      this.getDocumentError();
      this.dialogError = true;
    },

    getDocumentError() {
      this.documentSetError = null;

      this.$store
        .dispatch("getErrorPSCItem", this.selectedItem.id)
        .then(response => {
          if (response.status === 200) {
            this.documentSetError = response.data;
          } else {
            this.processDocumentErrorFailure();
          }
        })
        .catch(err => {
          console.log(err);
          this.processDocumentErrorFailure();
        });
    },

    processDocumentErrorFailure() {
      if (this.selectedItem) {
        this.$toasted.global.toast_error({
          message:
            "No se han podido recuperar los detalles de error del sobre: " +
            this.selectedItem.documentSetName
        });
      }
      this.dialogError = false;
    },
    /** END DOCUMENT ERROR  */

    resetSelectedItem() {
      this.editedIndex = -1;
      this.selectedItem = -1;
    },
    openMoreOptionsMenu(item) {
      this.moreOptionsMenu = true;
      this.selectedItem = item;
      this.documentDetailsLoading = true;
      this.getDocumentDetails(void 0, false);
    },
    downloadAllDocuments() {
      //documentset tiene los docs
      for (const document of this.documentSetDetails.documents) {
        this.downloadDocumentFromDocumentSet(document);
      }
    }
  }
};
</script>

<style scoped>
@media (max-width: 635px) {
  .btn--expanded {
    width: 95%;
  }
}
.toast-center {
  text-align: center !important;
}
</style>
