const PSC_RECIPIENT_ACTION_TYPES = [
  { value: 20, text: "Aceptación simple (sin firma digital)" },
  { value: 21, text: "Aceptación simple" },
  { value: 30, text: "Firma manuscrita" },
  { value: 40, text: "Firma con OTP" },
  { value: 50, text: "Firma manuscrita + OTP" },
  { value: 51, text: "Firma manuscrita + WhatsApp" },
  { value: 60, text: "Certificado de firma (sólo para PC)" }
];

const PSC_RECIPIENT_DEFAULT_PHONE = {
  number: "",
  valid: false,
  country: undefined
};

const PSC_CREATE_DOCUMENTSET_MAX_SIZE_IN_BYTES = 30000000; //28.6MB

const PSC_RECIPIENT_AUTH_TYPES = [
  { value: 0, text: "Sin autenticación previa" },
  { value: 10, text: "Aceptación de datos personales" },
  { value: 20, text: "Clave de acceso" },
  { value: 30, text: "Autenticación MRZ" },
  { value: 40, text: "Envío de SMS" }
];

const PSC_DEFAULT_DOCUMENTSET = {
  expirationDaysTimeout: 7,
  reminderDays: 3,
  showReminderDays: false,
  ltv: false,
  name: "Sobre con fecha: " + new Date().toLocaleDateString(),
  corporateSignatureType: 0,
  corporateSignatureId: "",
  sendMethod: 1
};

const PSC_DEFAULT_RECIPIENT = {
  name: null,
  email: null,
  role: 30,
  authType: 0,
  accessKeyResponse: null,
  accessKeyChallenge: null,
  accessKeyFormat: null,
  widgets: [],
  cardId: null,
  phoneNumber: null,
  includeCustomText: true,
  attachments: []
};

const PSC_CORPORATE_SIGNATURE_ITEMS = [
  { value: 0, text: "No añadir" },
  { value: 1, text: "Añadir al inicio" },
  { value: 2, text: "Añadir al final" },
  { value: 3, text: "Añadir al principio y al final" }
];

const PSC_RECIPIENT_OTP_IGNORED_COUNTRIES = [
  ["Afghanistan (‫افغانستان‬‎)", "af", "93"],
  ["Albania (Shqipëri)", "al", "355"],
  ["Algeria (‫الجزائر‬‎)", "dz", "213"],
  ["American Samoa", "as", "1684"],
  ["Angola", "ao", "244"],
  ["Anguilla", "ai", "1264"],
  ["Antigua and Barbuda", "ag", "1268"],
  ["Armenia (Հայաստան)", "am", "374"],
  ["Aruba", "aw", "297"],
  ["Australia", "au", "61", 0],
  ["Austria (Österreich)", "at", "43"],
  ["Azerbaijan (Azərbaycan)", "az", "994"],
  ["Bahamas", "bs", "1242"],
  ["Bahrain (‫البحرين‬‎)", "bh", "973"],
  ["Bangladesh (বাংলাদেশ)", "bd", "880"],
  ["Barbados", "bb", "1246"],
  ["Belarus (Беларусь)", "by", "375"],
  ["Belize", "bz", "501"],
  ["Benin (Bénin)", "bj", "229"],
  ["Bermuda", "bm", "1441"],
  ["Bhutan (འབྲུག)", "bt", "975"],
  ["Bosnia and Herzegovina (Босна и Херцеговина)", "ba", "387"],
  ["Botswana", "bw", "267"],
  ["British Indian Ocean Territory", "io", "246"],
  ["British Virgin Islands", "vg", "1284"],
  ["Brunei", "bn", "673"],
  ["Bulgaria (България)", "bg", "359"],
  ["Burkina Faso", "bf", "226"],
  ["Burundi (Uburundi)", "bi", "257"],
  ["Cambodia (កម្ពុជា)", "kh", "855"],
  ["Cape Verde (Kabu Verdi)", "cv", "238"],
  ["Caribbean Netherlands", "bq", "599", 1],
  ["Cayman Islands", "ky", "1345"],
  ["Central African Republic (République centrafricaine)", "cf", "236"],
  ["Chad (Tchad)", "td", "235"],
  ["China (中国)", "cn", "86"],
  ["Christmas Island", "cx", "61", 2],
  ["Cocos (Keeling) Islands", "cc", "61", 1],
  ["Comoros (‫جزر القمر‬‎)", "km", "269"],
  ["Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)", "cd", "243"],
  ["Congo (Republic) (Congo-Brazzaville)", "cg", "242"],
  ["Cook Islands", "ck", "682"],
  ["Croatia (Hrvatska)", "hr", "385"],
  ["Curaçao", "cw", "599", 0],
  ["Cyprus (Κύπρος)", "cy", "357"],
  ["Czech Republic (Česká republika)", "cz", "420"],
  ["Djibouti", "dj", "253"],
  ["Dominica", "dm", "1767"],
  ["Egypt (‫مصر‬‎)", "eg", "20"],
  ["El Salvador", "sv", "503"],
  ["Equatorial Guinea (Guinea Ecuatorial)", "gq", "240"],
  ["Eritrea", "er", "291"],
  ["Estonia (Eesti)", "ee", "372"],
  ["Ethiopia", "et", "251"],
  ["Falkland Islands (Islas Malvinas)", "fk", "500"],
  ["Faroe Islands (Føroyar)", "fo", "298"],
  ["Fiji", "fj", "679"],
  ["Finland (Suomi)", "fi", "358", 0],
  ["French Guiana (Guyane française)", "gf", "594"],
  ["French Polynesia (Polynésie française)", "pf", "689"],
  ["Gabon", "ga", "241"],
  ["Gambia", "gm", "220"],
  ["Georgia (საქართველო)", "ge", "995"],
  ["Ghana (Gaana)", "gh", "233"],
  ["Gibraltar", "gi", "350"],
  ["Greenland (Kalaallit Nunaat)", "gl", "299"],
  ["Grenada", "gd", "1473"],
  ["Guadeloupe", "gp", "590", 0],
  ["Guam", "gu", "1671"],
  ["Guernsey", "gg", "44", 1],
  ["Guinea (Guinée)", "gn", "224"],
  ["Guinea-Bissau (Guiné Bissau)", "gw", "245"],
  ["Guyana", "gy", "592"],
  ["Haiti", "ht", "509"],
  ["Hong Kong (香港)", "hk", "852"],
  ["Hungary (Magyarország)", "hu", "36"],
  ["Iceland (Ísland)", "is", "354"],
  ["India (भारत)", "in", "91"],
  ["Indonesia", "id", "62"],
  ["Iran (‫ایران‬‎)", "ir", "98"],
  ["Iraq (‫العراق‬‎)", "iq", "964"],
  ["Isle of Man", "im", "44", 2],
  ["Japan (日本)", "jp", "81"],
  ["Jersey", "je", "44", 3],
  ["Jordan (‫الأردن‬‎)", "jo", "962"],
  ["Kazakhstan (Казахстан)", "kz", "7", 1],
  ["Kenya", "ke", "254"],
  ["Kiribati", "ki", "686"],
  ["Kosovo", "xk", "383"],
  ["Kyrgyzstan (Кыргызстан)", "kg", "996"],
  ["Laos (ລາວ)", "la", "856"],
  ["Latvia (Latvija)", "lv", "371"],
  ["Lebanon (‫لبنان‬‎)", "lb", "961"],
  ["Lesotho", "ls", "266"],
  ["Liberia", "lr", "231"],
  ["Libya (‫ليبيا‬‎)", "ly", "218"],
  ["Liechtenstein", "li", "423"],
  ["Lithuania (Lietuva)", "lt", "370"],
  ["Macau (澳門)", "mo", "853"],
  ["Macedonia (FYROM) (Македонија)", "mk", "389"],
  ["Madagascar (Madagasikara)", "mg", "261"],
  ["Malawi", "mw", "265"],
  ["Malaysia", "my", "60"],
  ["Maldives", "mv", "960"],
  ["Mali", "ml", "223"],
  ["Malta", "mt", "356"],
  ["Marshall Islands", "mh", "692"],
  ["Martinique", "mq", "596"],
  ["Mauritania (‫موريتانيا‬‎)", "mr", "222"],
  ["Mauritius (Moris)", "mu", "230"],
  ["Mayotte", "yt", "262", 1],
  ["Micronesia", "fm", "691"],
  ["Moldova (Republica Moldova)", "md", "373"],
  ["Monaco", "mc", "377"],
  ["Mongolia (Монгол)", "mn", "976"],
  ["Montenegro (Crna Gora)", "me", "382"],
  ["Montserrat", "ms", "1664"],
  ["Mozambique (Moçambique)", "mz", "258"],
  ["Myanmar (Burma) (မြန်မာ)", "mm", "95"],
  ["Namibia (Namibië)", "na", "264"],
  ["Nauru", "nr", "674"],
  ["Nepal (नेपाल)", "np", "977"],
  ["New Caledonia (Nouvelle-Calédonie)", "nc", "687"],
  ["New Zealand", "nz", "64"],
  ["Niger (Nijar)", "ne", "227"],
  ["Nigeria", "ng", "234"],
  ["Niue", "nu", "683"],
  ["Norfolk Island", "nf", "672"],
  ["North Korea (조선 민주주의 인민 공화국)", "kp", "850"],
  ["Northern Mariana Islands", "mp", "1670"],
  ["Oman (‫عُمان‬‎)", "om", "968"],
  ["Pakistan (‫پاکستان‬‎)", "pk", "92"],
  ["Palau", "pw", "680"],
  ["Palestine (‫فلسطين‬‎)", "ps", "970"],
  ["Papua New Guinea", "pg", "675"],
  ["Philippines", "ph", "63"],
  ["Qatar (‫قطر‬‎)", "qa", "974"],
  ["Réunion (La Réunion)", "re", "262", 0],
  ["Russia (Россия)", "ru", "7", 0],
  ["Rwanda", "rw", "250"],
  ["Saint Barthélemy", "bl", "590", 1],
  ["Saint Helena", "sh", "290"],
  ["Saint Kitts and Nevis", "kn", "1869"],
  ["Saint Lucia", "lc", "1758"],
  ["Saint Martin (Saint-Martin (partie française))", "mf", "590", 2],
  ["Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)", "pm", "508"],
  ["Saint Vincent and the Grenadines", "vc", "1784"],
  ["Samoa", "ws", "685"],
  ["San Marino", "sm", "378"],
  ["São Tomé and Príncipe (São Tomé e Príncipe)", "st", "239"],
  ["Seychelles", "sc", "248"],
  ["Sierra Leone", "sl", "232"],
  ["Singapore", "sg", "65"],
  ["Sint Maarten", "sx", "1721"],
  ["Slovakia (Slovensko)", "sk", "421"],
  ["Slovenia (Slovenija)", "si", "386"],
  ["Solomon Islands", "sb", "677"],
  ["Somalia (Soomaaliya)", "so", "252"],
  ["South Africa", "za", "27"],
  ["South Korea (대한민국)", "kr", "82"],
  ["South Sudan (‫جنوب السودان‬‎)", "ss", "211"],
  ["Sri Lanka (ශ්‍රී ලංකාව)", "lk", "94"],
  ["Sudan (‫السودان‬‎)", "sd", "249"],
  ["Suriname", "sr", "597"],
  ["Svalbard and Jan Mayen", "sj", "47", 1],
  ["Swaziland", "sz", "268"],
  ["Sweden (Sverige)", "se", "46"],
  ["Syria (‫سوريا‬‎)", "sy", "963"],
  ["Taiwan (台灣)", "tw", "886"],
  ["Tajikistan", "tj", "992"],
  ["Tanzania", "tz", "255"],
  ["Thailand (ไทย)", "th", "66"],
  ["Timor-Leste", "tl", "670"],
  ["Togo", "tg", "228"],
  ["Tokelau", "tk", "690"],
  ["Tonga", "to", "676"],
  ["Trinidad and Tobago", "tt", "1868"],
  ["Turkey (Türkiye)", "tr", "90"],
  ["Turkmenistan", "tm", "993"],
  ["Turks and Caicos Islands", "tc", "1649"],
  ["Tuvalu", "tv", "688"],
  ["U.S. Virgin Islands", "vi", "1340"],
  ["Uganda", "ug", "256"],
  ["Ukraine (Україна)", "ua", "380"],
  ["Uzbekistan (Oʻzbekiston)", "uz", "998"],
  ["Vanuatu", "vu", "678"],
  ["Vatican City (Città del Vaticano)", "va", "39", 1],
  ["Vietnam (Việt Nam)", "vn", "84"],
  ["Wallis and Futuna (Wallis-et-Futuna)", "wf", "681"],
  ["Western Sahara (‫الصحراء الغربية‬‎)", "eh", "212", 1],
  ["Yemen (‫اليمن‬‎)", "ye", "967"],
  ["Zambia", "zm", "260"],
  ["Zimbabwe", "zw", "263"],
  ["Åland Islands", "ax", "358", 1]
];

export default {
  PSC_RECIPIENT_DEFAULT_PHONE: PSC_RECIPIENT_DEFAULT_PHONE,
  PSC_CREATE_DOCUMENTSET_MAX_SIZE_IN_BYTES: PSC_CREATE_DOCUMENTSET_MAX_SIZE_IN_BYTES,
  PSC_RECIPIENT_ACTION_TYPES: PSC_RECIPIENT_ACTION_TYPES,
  PSC_RECIPIENT_AUTH_TYPES: PSC_RECIPIENT_AUTH_TYPES,
  PSC_RECIPIENT_OTP_IGNORED_COUNTRIES: PSC_RECIPIENT_OTP_IGNORED_COUNTRIES.map(
    country => country[1].toUpperCase()
  ),
  PSC_RECIPIENT_OTP_PREFERRED_COUNTRIES: ["ES"],
  PSC_DEFAULT_DOCUMENTSET: PSC_DEFAULT_DOCUMENTSET,
  PSC_DEFAULT_RECIPIENT: PSC_DEFAULT_RECIPIENT,
  PSC_CORPORATE_SIGNATURE_ITEMS: PSC_CORPORATE_SIGNATURE_ITEMS
};
