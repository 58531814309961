<template>
  <div v-if="visible">
    <v-card class="pa-1" :color="bgColor" dark>
      <v-card-text>
        <span>{{ message }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0 mt-2"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Loading",

  data: () => ({
    selectSubView: 0
  }),

  props: {
    message: String,
    visible: Boolean,
    bgColor: {
      type: String,
      default: "primary"
    }
  }
};
</script>
